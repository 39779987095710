import React, { useState, SyntheticEvent, ChangeEvent } from "react";
import { Achilles } from "./svg/Achilles";

const encode = (data: Record<string, string>) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

const ContactForm: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e: SyntheticEvent) => {
    const data = { name, email, message };

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data }),
    })
      .then(() => setSuccess(true))
      .catch((error) => alert(error));

    e.preventDefault();
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.target.name) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
        break;
    }
  };

  return (
    <>
      {success ? (
        <p>Thanks for your message. We will be in touch soon!</p>
      ) : (
        <>
          <form className="contact-form" onSubmit={handleSubmit}>
            <label htmlFor="yourname">Your Name:</label>
            <input
              id="yourname"
              type="text"
              name="name"
              value={name}
              onChange={handleChange}
            />
            <label htmlFor="youremail">Your Email:</label>
            <input
              type="email"
              name="email"
              id="youremail"
              value={email}
              onChange={handleChange}
            />
            <label htmlFor="yourmessage">Message:</label>
            <textarea
              id="yourmessage"
              name="message"
              value={message}
              onChange={handleChange}
            />
            <button className="btn btn-primary" type="submit">
              Send
            </button>
          </form>

          <div className="contact-form-footer">
            <Achilles />
            Achilles ID 00073334
          </div>
        </>
      )}
    </>
  );
};

export { ContactForm };
