import avif1 from "./avif/scene00001.avif";
import avif3 from "./avif/scene00003.avif";
import avif5 from "./avif/scene00005.avif";
import avif7 from "./avif/scene00007.avif";
import avif9 from "./avif/scene00009.avif";
import avif11 from "./avif/scene00011.avif";
import avif13 from "./avif/scene00013.avif";
import avif15 from "./avif/scene00015.avif";
import avif17 from "./avif/scene00017.avif";
import avif19 from "./avif/scene00019.avif";
import avif21 from "./avif/scene00021.avif";
import avif23 from "./avif/scene00023.avif";

import webp1 from "./webp/scene00001.webp";
import webp3 from "./webp/scene00003.webp";
import webp5 from "./webp/scene00005.webp";
import webp7 from "./webp/scene00007.webp";
import webp9 from "./webp/scene00009.webp";
import webp11 from "./webp/scene00011.webp";
import webp13 from "./webp/scene00013.webp";
import webp15 from "./webp/scene00015.webp";
import webp17 from "./webp/scene00017.webp";
import webp19 from "./webp/scene00019.webp";
import webp21 from "./webp/scene00021.webp";
import webp23 from "./webp/scene00023.webp";

import jpeg1 from "./jpeg/scene00001.jpg";
import jpeg3 from "./jpeg/scene00003.jpg";
import jpeg5 from "./jpeg/scene00005.jpg";
import jpeg7 from "./jpeg/scene00007.jpg";
import jpeg9 from "./jpeg/scene00009.jpg";
import jpeg11 from "./jpeg/scene00011.jpg";
import jpeg13 from "./jpeg/scene00013.jpg";
import jpeg15 from "./jpeg/scene00015.jpg";
import jpeg17 from "./jpeg/scene00017.jpg";
import jpeg19 from "./jpeg/scene00019.jpg";
import jpeg21 from "./jpeg/scene00021.jpg";
import jpeg23 from "./jpeg/scene00023.jpg";

const SunsetTurbineAvif = [
  avif1,
  avif3,
  avif5,
  avif7,
  avif9,
  avif11,
  avif13,
  avif15,
  avif17,
  avif19,
  avif21,
  avif23,
];

const SunsetTurbineWebp = [
  webp1,
  webp3,
  webp5,
  webp7,
  webp9,
  webp11,
  webp13,
  webp15,
  webp17,
  webp19,
  webp21,
  webp23,
];

const SunsetTurbineJpeg = [
  jpeg1,
  jpeg3,
  jpeg5,
  jpeg7,
  jpeg9,
  jpeg11,
  jpeg13,
  jpeg15,
  jpeg17,
  jpeg19,
  jpeg21,
  jpeg23,
];

declare global {
  interface Window {
    imageSupport: string[];
  }
}

const sunsetTurbine = (index: number): string => {
  if (window.imageSupport.includes("avif")) {
    return SunsetTurbineAvif[index];
  } else if (window.imageSupport.includes("webp")) {
    return SunsetTurbineWebp[index];
  } else {
    return SunsetTurbineJpeg[index];
  }
};

export { sunsetTurbine };
