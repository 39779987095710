import React from "react";

const Achilles = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="157"
        height="32"
        viewBox="0 0 157 32"
    >
        <defs>
            <path
                id="path-1"
                d="M1.35534071 0.320117821L15.031708 0.320117821 15.031708 19.4459318 1.35534071 19.4459318z"
            ></path>
            <path
                id="path-3"
                d="M6.12553717 6.1408L26.2576611 6.1408 26.2576611 32.4347826 6.12553717 32.4347826z"
            ></path>
        </defs>
        <g fill="black" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-1 -1)">
                <path
                    d="M90.186 30.704h-3.479V4.032l3.332-1.163v11.98c1.535-.844 2.96-1.948 4.571-2.453 3.62-1.131 6.644.84 6.848 4.612.244 4.514.057 9.05.057 13.653h-3.57V19.355c0-.422-.007-.845-.017-1.268-.093-4.059-2.955-4.222-5.352-3.135-1.562.708-2.59 1.695-2.453 3.787.23 3.52.063 7.067.063 10.603v1.362z"
                    className="header__logo-letter"
                ></path>
                <path
                    d="M68.434 30.738c-1.283 0-2.468.058-3.64-.037-.335-.027-.764-.432-.928-.77-.778-1.62-1.482-3.274-2.262-5.035-2.058.734-4.06 1.446-6.064 2.164-2.34.84-4.686 1.657-7.012 2.538-.493.187-.93.572-1.222 1.013-.223.334-1.22.267-2.107.252.85-1.9 1.584-3.56 2.333-5.213a6345.747 6345.747 0 018.414-18.521c.14-.307.435-.707.7-.742.225-.03.617.356.753.646 3.653 7.72 7.282 15.453 10.913 23.184.047.099.052.216.122.52M55.689 11.55c-2.562 5.714-4.998 11.145-7.554 16.851l12.978-4.633L55.69 11.55"
                    className="header__logo-letter"
                ></path>
                <path
                    d="M128.284 19.647c-.257 1.846-.094 3.86.463 5.218 1.847 4.505 8.014 4.778 11.37 1.273.475-.496.854-1.088 1.335-1.58.205-.212.523-.09.993-.148-2.088 6.089-8.018 8.52-12.957 5.628-3.093-1.809-4.217-4.943-4.103-8.382.135-4.056 1.881-7.252 5.794-8.905 3.855-1.629 8.601.185 9.971 3.825.342.908.427 1.913.673 3.07h-13.539zm8.891-1.421c.06-2.927-1.664-4.668-4.417-4.522-2.221.119-4.2 2.37-4.428 4.568 2.74 0 5.996-.046 8.845-.046z"
                    className="header__logo-letter"
                ></path>
                <path
                    d="M122.859 30.676h-3.474c-.024-.39-.058-.726-.058-1.06-.003-8.1.01-16.202-.021-24.302-.004-.829.21-1.25 1.046-1.44.81-.183 1.583-.534 2.507-.86v27.662z"
                    className="header__logo-letter"
                ></path>
                <path
                    d="M83.214 14.566l-.758.756c-3.762-3.797-8.689-2.166-10.245 1.231-1.145 2.498-1.166 5.092.06 7.579 2.305 4.68 7.348 5.295 10.802 1.385.36-.409.863-.691 1.298-1.03-.18.564-.263 1.192-.561 1.687-1.626 2.685-3.775 4.658-7.101 4.82-4.521.218-8.078-3.064-8.647-7.931-.7-5.995 4.098-11.206 10.1-10.938 1.96.086 3.597.904 5.052 2.441"
                    className="header__logo-letter"
                ></path>
                <path
                    d="M115.759 5.536V30.67h-3.504c-.022-.395-.057-.73-.057-1.067-.003-7.264.012-14.53-.022-21.795-.003-.795.22-1.177.983-1.376.838-.217 1.645-.56 2.6-.896"
                    className="header__logo-letter"
                ></path>
                <path
                    d="M108.546 13.097v17.57h-3.482c-.023-.382-.062-.716-.062-1.051-.004-4.76.016-9.518-.021-14.278-.007-.795.228-1.186.996-1.38.809-.204 1.59-.527 2.57-.861"
                    className="header__logo-letter"
                ></path>
                <path
                    fill="#E82C2E"
                    d="M108.374 8.248c.07.223.108.294.112.368.19 3.179-.086 3.606-3.419 4.249 0-.964-.039-2 .027-2.944.015-.248.298-.59.541-.692.874-.366 1.782-.647 2.739-.98"
                ></path>
                <g transform="translate(142.717 11.74)">
                    <mask id="mask-2" fill="#fff">
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <path
                        d="M14.652 12.273l-.028-.083c-.017-.03-.039-.057-.058-.088-.662-1.285-1.829-2.031-2.158-2.222-.774-.54-1.624-.985-2.483-1.422-1.298-.656-2.67-1.216-3.842-2.053-.667-.477-1.307-1.382-1.422-2.172-.278-1.934 1.704-3.11 3.966-2.831 2.11.26 2.85 1.193 4.39 1.865-.034.032.115-.087.238-.228.12-.135.212-.292.338-.467C11.037.52 8.286-.278 5.176.8 2.004 1.9.67 5.377 2.56 7.91c.773 1.033 1.961 1.807 3.074 2.524 1.476.95 3.13 1.627 4.582 2.61 1.629 1.102 1.532 3.074-.054 4.241-1.407 1.034-2.947 1.097-4.456.412-1.235-.56-1.545-.588-3.583-2.4-.155.124-.462.366-.767.605 1.591 2.153 4.057 3.526 6.543 3.543 1.6.01 3.089-.23 4.342-.945v.015s1.464-.73 2.273-2.36c.022-.037.044-.072.066-.112.027-.064.048-.128.071-.194.19-.457.327-.975.371-1.562a4.052 4.052 0 00-.37-2.015"
                        className="header__logo-letter"
                        mask="url(#mask-2)"
                    ></path>
                </g>
                <g transform="translate(.168 .176)">
                    <g fill="#E82C2E">
                        <path d="M6.126 32.435c3.038-6.826 6.077-13.653 9.117-20.477.824-1.85 1.652-3.697 2.6-5.817l8.415 19.112c-2.257.815-4.403 1.6-6.554 2.368-4.142 1.48-8.29 2.948-12.43 4.43-.244.088-.46.255-.687.384h-.461z"></path>
                        <path d="M21.776.824c1.031.267 2.067.514 3.092.803 8.825 2.492 14.48 10.933 13.534 20.084-.324 3.124-1.345 5.946-3.149 8.667-1.806-3.848-3.548-7.553-5.285-11.26-2.75-5.867-5.492-11.735-8.243-17.6-.112-.243-.27-.463-.41-.694h.461z"></path>
                        <path d="M.832 18.13c.254-1.114.475-2.236.764-3.34C3.469 7.617 9.716 2.086 17.038 1.104c.07-.008.148.026.318.06L4.175 30.254c-1.256-1.743-2.037-3.53-2.551-5.437-.307-1.14-.53-2.304-.792-3.457v-3.23z"></path>
                    </g>
                    <mask fill="#fff">
                        <use xlinkHref="#path-3"></use>
                    </mask>
                </g>
            </g>
        </g>
    </svg>
)

export { Achilles };
