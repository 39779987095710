import React from "react";

import { CardImage } from "./CardImage";
import { CardText } from "./CardText";

const Card: React.FC<{
  right: boolean;
  colorStyle: string;
  name: string;
  text: JSX.Element | string;
  image?: JSX.Element;
  imageWidth: number;
}> = ({ right, colorStyle, name, text, image, imageWidth }) => {
  if (image === undefined) {
    return (
      <div className={`card ${colorStyle}`}>
        <div className="row no-gutters">
          <CardText width={12} name={name} text={text} />
        </div>
      </div>
    );
  }
  if (text === undefined) {
    return (
      <div className={`card ${colorStyle}`}>
        <div className="row no-gutters">
          <div className="card-body">
            <h4 className="card-title">{name}</h4>
          </div>
          <CardImage width={12} image={image} />
        </div>
      </div>
    );
  }
  return (
    <div className={`card ${colorStyle}`}>
      <div className="row no-gutters">
        {right ? (
          <>
            <CardText width={12 - imageWidth} name={name} text={text} />
            <CardImage width={imageWidth} image={image} />
          </>
        ) : (
          <>
            <CardImage width={imageWidth} image={image} />
            <CardText width={12 - imageWidth} name={name} text={text} />
          </>
        )}
      </div>
    </div>
  );
};

export { Card };
