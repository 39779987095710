import React from "react";

const FlowchartMiddleVertical: React.FC<{ name: string }> = ({ name }) => (
  <svg
    aria-labelledby={name}
    id="svg"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    className={name}
    viewBox="0 0 2 1"
    preserveAspectRatio="none"
  >
    <polygon className="polygon-1" points="0,-1, 2,-1, 2,0, 1,1, 0,0" />
    <polygon className="polygon-2" points="0,0, 1,1, 2,0, 2,2, 0,2" />
  </svg>
);

export { FlowchartMiddleVertical };
