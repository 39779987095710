import React from "react";

import waves from "../img/waves3.png";

const PageHeader: React.FC<{
  title: string;
  content?: JSX.Element | string;
}> = ({ title, content }) => (
  <div className="old-header">
    <div className="jumbotron">
      <h1 className="display-4">{title}</h1>
      <div className="lead">{content}</div>
    </div>
    <div className="waves-container">
      <img src={waves} id="waves" alt="" />
    </div>
  </div>
);

export { PageHeader };
