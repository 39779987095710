import React from "react";

const TriangleMiddleFlowchart: React.FC<{ name: string }> = ({ name }) => (
  <svg
    aria-labelledby={name}
    id="svg"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    className={name}
    viewBox="0 0 1 2"
    preserveAspectRatio="none"
  >
    <polygon className="polygon-1" points="-1,0, 0,0, 1,1, 0,2, -1,2" />
    <polygon className="polygon-2" points="0,0, 2,0, 2,3, 0,3, 0,2, 1,1" />
  </svg>
);

export { TriangleMiddleFlowchart };
