import React from "react";

import { Card } from "./Card";

const PageContent: React.FC<{
  content: {
    name: string;
    text: JSX.Element | string;
    image?: JSX.Element;
  }[];
  imageWidth: number;
}> = ({ content, imageWidth }) => (
  <>
    {content.map((value, index) => (
      <Card
        key={index}
        imageWidth={imageWidth}
        colorStyle={`card-${(index % 3) + 1}`}
        right={Boolean(index % 2)}
        name={value.name}
        text={value.text}
        image={value.image}
      />
    ))}
  </>
);

export { PageContent };
