import React from "react";

const Fade: React.FC<{ name: string }> = ({ name }) => (
  <svg
    className={`square ${name}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1 1"
    preserveAspectRatio="none"
  >
    <defs>
      <linearGradient id="FadeOut" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="5%" stopColor="#EAEAEB" stopOpacity="0" />
        <stop offset="95%" stopColor="#EAEAEB" />
      </linearGradient>
      <linearGradient id="FadeIn" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop offset="5%" stopColor="#EAEAEB" stopOpacity="0" />
        <stop offset="95%" stopColor="#EAEAEB" />
      </linearGradient>
    </defs>
    <polygon fill={`url(#Fade${name})`} points="0,0, 0,1 1,1 1,0"></polygon>
  </svg>
);

export { Fade };
