import React from "react";

import { Ai } from "./svg/Ai";
import { BigData } from "./svg/BigData";
import { MechanicalAnalysis } from "./svg/MechanicalAnalysis";
import { NuclearPowerPlant } from "./svg/NuclearPowerPlant";
import { Sensor } from "./svg/Sensor";
import { WindTurbine } from "./svg/WindTurbine";

const SplashIcons: React.FC = () => (
  <div className="cover-icons">
    <div className="cover-icon-container" id="wind-turbine-cover">
      <WindTurbine />
    </div>
    <div className="cover-icon-container" id="nuclear-plant-cover">
      <NuclearPowerPlant />
    </div>
    <div className="cover-icon-container" id="ai-cover">
      <Ai />
    </div>
    <div className="cover-icon-container" id="sensor-cover">
      <Sensor />
    </div>
    <div className="cover-icon-container" id="big-data-cover">
      <BigData />
    </div>
    <div className="cover-icon-container" id="mech-anal-cover">
      <MechanicalAnalysis />
    </div>
    <div className="icon-label" id="turbine-icon-label">
      Wind
    </div>
    <div className="icon-label" id="nuclear-icon-label">
      Small Modular Reactors
    </div>
    <div className="icon-label" id="ai-icon-label">
      Machine Learning
    </div>
    <div className="icon-label" id="sensor-icon-label">
      Sensors
    </div>
    <div className="icon-label" id="bigdata-icon-label">
      Data Analysis
    </div>
    <div className="icon-label" id="mechanicalanalysis-icon-label">
      Mechanical Analysis
    </div>
  </div>
);

export { SplashIcons };
