import React from "react";

const Footer: React.FC = () => (
  <footer>
    Eleven-I is a limited company registered in England and Wales. Company
    number: 12201098
  </footer>
);

export { Footer };
