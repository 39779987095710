import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import headerLogo from "../img/header-logo-min.svg";

const NavBar: React.FC = () => (
  <Navbar bg="light" expand="lg" sticky="top">
    <Navbar.Brand href="/">
      <img src={headerLogo} height="50" alt="Eleven-I" loading="lazy" />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <NavDropdown title="Industries" id="basic-nav-dropdown">
          <NavDropdown.Item href="/wind">Wind</NavDropdown.Item>
          <NavDropdown.Item href="/industrial">Industrial</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Tools and Expertise" id="basic-nav-dropdown">
          <NavDropdown.Item href="/platform">Sensor Platform</NavDropdown.Item>
          <NavDropdown.Item href="/analysis">Data Analysis</NavDropdown.Item>
          <NavDropdown.Item href="/experience">Experience</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="/team">Team</Nav.Link>
        <Nav.Link href="/downloads">Downloads</Nav.Link>
        <Nav.Link href="/contact">Contact Us</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export { NavBar };
