import React from "react";

const CardImage: React.FC<{ image: JSX.Element; width: number }> = ({
  image,
  width,
}) => {
  return <div className={`col-md-${width}`}>{image}</div>;
};

export { CardImage };
