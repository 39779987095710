import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import "./styles/style.css";

import { NavBar } from "./components/NavBar";
import { Home } from "./pages/Home";
import { Team } from "./pages/Team";
import { Footer } from "./components/Footer";
import { Platform } from "./pages/Platform";
import { Analysis } from "./pages/Analysis";
import { Contact } from "./pages/Contact";
import { Downloads } from "./pages/Downloads";
import { Experience } from "./pages/Experience";
import { Wind } from "./pages/Wind";
import { Industrial } from "./pages/Industrial";

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-79FQPNE8G7",
        gaOptions: { cookieFlags: "SameSite=None; Secure" },
      },
    ]);
    const currentPath = document.location.pathname + document.location.search;
    ReactGA.send({ hitType: "pageview", page: currentPath });
  }, []);

  return (
    <React.StrictMode>
      <NavBar />
      <Router>
        <Routes>
          <Route element={<Team />} path="/team/" />
          <Route element={<Platform />} path="/platform/" />
          <Route element={<Analysis />} path="/analysis/" />
          <Route element={<Contact />} path="/contact/" />
          <Route element={<Downloads />} path="/downloads/" />
          <Route element={<Experience />} path="/experience/" />
          <Route element={<Wind />} path="/wind/" />
          <Route element={<Industrial />} path="/industrial/" />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
      <Footer />
    </React.StrictMode>
  );
};

createRoot(document.getElementById("root") as HTMLElement).render(<App />);
