import React from "react";

const PageStart: React.FC = () => (
  <svg
    aria-labelledby="pageStart"
    id="svg"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    className="page-start-arrow"
    viewBox="0 0 2 1"
    preserveAspectRatio="none"
  >
    <polygon points="0,0 1,1 2,0, 2,2, 0,2" />
  </svg>
);

export { PageStart };
