import React from "react";

import flyerThumbnail from "../img/flyer-thumbnail.jpg";
import flyerPdf from "../download/Eleven-I Flyer October 2021.pdf";

import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";

const Downloads: React.FC = () => (
  <section>
    <PageHeader title="Downloads" />
    <PageContent
      imageWidth={4}
      content={[
        {
          name: "Eleven-I Flyer",
          text: (
            <>
              <a href={flyerPdf} download>
                Click here
              </a>{" "}
              to download the latest Eleven-I flyer.
            </>
          ),
          image: (
            <a href={flyerPdf} download>
              <img src={flyerThumbnail} className="card-img" alt="Flyer" />
            </a>
          ),
        },
      ]}
    />
  </section>
);

export { Downloads };
