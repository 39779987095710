import React from "react";

import pro from "../img/blade-wobbling/blade-wobbling.jpg";
import monitoringJpg from "../img/monitoring-acceleration/monitoring-acceleration.jpg";
import monitoringAvif from "../img/monitoring-acceleration/monitoring-acceleration.avif";
import monitoringWebp from "../img/monitoring-acceleration/monitoring-acceleration.webp";
import transport from "../img/blade-transport.png";
import offshoreWind from "../img/offshore-wind-crop.jpg";

import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";

const Wind: React.FC = () => (
  <section id="about">
    <PageHeader
      title="Wind Services"
      content={
        <>
          <h2>Highlights</h2>
          <ul className="blue-boxes no-list-style">
            <li>
              Gather blade condition analysis 24/7 – during challenging weather,
              high winds and low temperatures.
            </li>
            <li>
              Preemptively identify early stages of damage before they become
              serious.
            </li>
            <li>
              Receive real time alerts if anything changes in your blades.
            </li>
            <li>Reduce the need for up-tower maintenance.</li>
            <li>Increase ROI from your assets.</li>
            <li>Avoid unplanned downtime.</li>
            <li>Optimise blade matching.</li>
          </ul>
        </>
      }
    />
    <PageContent
      imageWidth={6}
      content={[
        {
          name: "All-weather 24/7 monitoring and analysis for wind turbine blades",
          text: (
            <>
              <img
                src={offshoreWind}
                alt="Offshore Wind Turbines"
                className="full-width-image"
              />
              <p>
                Understanding what happens to assets during stressful
                conditions, when they are most likely to exhibit abnormal
                behaviour, will reduce unforeseen breakdowns, minimise downtime
                and lower maintenance costs.
              </p>
              <p>
                When relying only on fair-weather monitoring methods, such as
                rope access, drones and camera inspection, it is not possible to
                get the full picture of blade performance.
              </p>
              <p>
                Being able to predict and identify early blade issues,
                particularly when they face high winds and poor conditions, is
                key to drawing maximum ROI.
              </p>
              <p style={{ fontWeight: "bold" }}>
                Easy integration. Hard to match analysis.
              </p>
              <p>
                The Eleven-I monitoring and analysis solution can easily
                integrate into new blades at the manufacturing stage, or be
                retrofitted on-site. Once our high-specification nodes are
                attached, they transmit detailed data to our Eleven-I Lab
                platform for continual analysis through our proprietary
                software. This platform can also use existing data streams to
                help identify issues.
              </p>

              <p style={{ fontWeight: "bold" }}>
                Reliable data means action can be taken before damage becomes
                serious
              </p>
              <p>
                By using automated comparisons against historical data, analysis
                of higher frequency signals, and precise pinpointing of
                orientation, we can swiftly detect even hard-to-notice signs of
                structural changes in turbine blades.
              </p>
              <p>
                You will be alerted to poor asset performance well before
                failure occurs, allowing targeted preventative maintenance to
                resolve issues such as:
              </p>
              <ul>
                <li>Manufacturing inconsistencies or icing.</li>
                <li>Developing cracks or bonding failures. </li>
                <li>Surface roughness and erosion.</li>
                <li>Changes in material flexibility. </li>
                <li>Anomalous vibrations, resonance and motion. </li>
                <li>Damping characteristics.</li>
              </ul>
              <div className="center">
                <a href="/contact" className="btn btn-primary middle-button">
                  FIND OUT MORE
                </a>
              </div>
            </>
          ),
        },
        {
          name: "Structural Health Monitoring",
          image: <picture>
            <source type="image/avif" srcSet={monitoringAvif} />
            <source type="image/webp" srcSet={monitoringWebp} />
            <img src={monitoringJpg} className="card-img" alt="Monitoring" />
          </picture>,

          text: (
            <>
              <p style={{ fontWeight: "bold" }}>
                Lab-grade equipment meets hands-on expertise
              </p>
              <p>
                If you’re concerned about blade movement, unusual vibrations, or
                other anomalies, we can help. Fixing our bespoke sensors to your
                assets is a non-disruptive and simple process. We can monitor
                and analyse as many or as few blades as you require.
              </p>
              <p>
                With industry-leading expertise in many fields within our team,
                we can of getting to the root of any blade issues. We bring a
                practical, efficient and hands-on approach so we can deliver
                pragmatic and economically viable solutions.
              </p>
              <ul>
                <li>
                  Our bespoke sensors bring lab-grade measurement to wherever
                  your blade production or storage facility may be located.
                </li>
                <li>
                  Eleven-I promise lab-grade data analysis, but guarantee
                  commercial solutions.
                </li>
                <li>
                  We can combine with and analyse already available data
                  streams.
                </li>
              </ul>
              <div className="center">
                <a href="/contact" className="btn btn-primary middle-button">
                  GET IN TOUCH
                </a>
              </div>
            </>
          ),
        },
        {
          name: "In-transit Monitoring",
          image: (
            <img
              src={transport}
              className="card-img"
              alt="In-transit Monitoring"
            />
          ),
          text: (
            <>
              <p>
                Ensure your assets remain safe during transit, with real-time
                alerts of any structural health issues.
              </p>
              <p>
                Should mishandling or accidental breakage take place, our
                multiple sensors will provide precise details of where and when
                damage occurred.
              </p>
            </>
          ),
        },
        {
          name: "Professional Services",
          image: (
            <img
              src={pro}
              className="card-img"
              alt="Person working on turbine blade"
            />
          ),
          text: (
            <p>
              Our pragmatic approach to investigating and resolving blade issues
              brings laboratory grade sensing to production and storage
              environments, alongside sophisticated analysis, and a
              results-driven approach to getting to the root of blade issues. If
              there’s a problem, we’ve got a solution.
            </p>
          ),
        },
      ]}
    />
  </section>
);

export { Wind };
