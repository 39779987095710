import React from "react";

const CardText: React.FC<{
  name: string;
  text: string | JSX.Element;
  width: number;
}> = ({ name, text, width }) => {
  return (
    <div className={`col-md-${width}`}>
      <div className="card-body">
        <h4 className="card-title">{name}</h4>
        {text}
      </div>
    </div>
  );
};

export { CardText };
