import React from "react";

import { TriangleLeftFlowchart } from "./svg/TriangleLeftFlowchart";

import { TriangleMiddleFlowchart } from "./svg/TriangleMiddleFlowchart";

const FlowGrid: React.FC = () => {
  return (
    <div className="flow-grid">
      <div className="flow-grid-row-headers">
        <h5>We Measure</h5>
        <h5>Comes from</h5>
        <h5>Root Cause</h5>
      </div>
      <ul className="no-list-style">
        <li className="flow-grid-row">
          <TriangleLeftFlowchart name="first horizontal" />
          <div className="first hasIcon">
            <h5>Resonant frequency</h5>
          </div>
          <TriangleMiddleFlowchart name="second horizontal" />
          <div className="second hasIcon">
            <h5>Change in stiffness</h5>
          </div>
          <TriangleMiddleFlowchart name="third horizontal" />
          <ul className="no-list-style sibling">
            <li className="third hasIcon">
              <h5>Manufacturing issues</h5>
            </li>
            <li className="third hasIcon">
              <h5>Fatigue Accumulation</h5>
            </li>
          </ul>
        </li>

        <li className="flow-grid-row">
          <TriangleLeftFlowchart name="first horizontal" />
          <div className="first hasIcon">
            <h5>Damping Ratio</h5>
          </div>
          <TriangleMiddleFlowchart name="second horizontal" />
          <div className="second hasIcon">
            <h5>Fatigue Accumulation</h5>
          </div>
          <TriangleMiddleFlowchart name="third horizontal" />
          <ul className="no-list-style sibling">
            <li className="third hasIcon">
              <h5>Fatigue Accumulation</h5>
            </li>
          </ul>
        </li>
        <li className="flow-grid-row">
          <TriangleLeftFlowchart name="first horizontal" />
          <div className="first hasIcon">
            <h5>Modal Analysis</h5>
          </div>
          <TriangleMiddleFlowchart name="second horizontal" />
          <div className="second hasIcon">
            <h5>Change in Characteristic Movement</h5>
          </div>
          <TriangleMiddleFlowchart name="third horizontal" />
          <ul className="no-list-style sibling">
            <li className="third hasIcon">
              <h5>Fatigue Accumulation</h5>
            </li>
            <li className="third hasIcon">
              <h5>Excessive Loading</h5>
            </li>
          </ul>
        </li>
        <li className="flow-grid-row">
          <TriangleLeftFlowchart name="first horizontal" />
          <div className="first hasIcon">
            <h5>Higher Frequency Response</h5>
          </div>
          <TriangleMiddleFlowchart name="second horizontal" />
          <div className="second hasIcon">
            <h5>Abnormal Vibration</h5>
          </div>
          <TriangleMiddleFlowchart name="third horizontal" />
          <ul className="no-list-style sibling">
            <li className="third hasIcon">
              <h5>Manufacturing issues</h5>
            </li>
            <li className="third hasIcon">
              <h5>Excessive Loading</h5>
            </li>
            <li className="third hasIcon">
              <h5>Hazardous Event</h5>
            </li>
          </ul>
        </li>

        <li className="flow-grid-row">
          <TriangleLeftFlowchart name="first horizontal" />
          <div className="first hasIcon">
            <h5>
              Change in Vibration Frequency Content at Nodes Between Nodes
            </h5>
          </div>
          <TriangleMiddleFlowchart name="second horizontal" />
          <div className="second hasIcon">
            <h5>Altered Transmissability</h5>
          </div>
          <TriangleMiddleFlowchart name="third horizontal" />
          <ul className="no-list-style sibling">
            <li className="third hasIcon">
              <h5>Hazardous Event</h5>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export { FlowGrid };
