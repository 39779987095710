import React from "react";

import nodeavif from "../img/node/node.avif";
import nodejpg from "../img/node/node.jpg";
import cloudlive from "../img/cloudlive.png";
import cloud from "../img/cloud.png";

import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";

const Platform: React.FC = () => (
  <section>
    <PageHeader title="The Eleven-I Sensor Platform" />
    <PageContent
      imageWidth={4}
      content={[
        {
          name: "Sensor Node",
          text: (
            <>
              <p>
                Our board reads and processes data from a built-in high
                specification digital accelerometer. It can transmit this
                information over a robust RS485 wired serial connection, or
                through various wireless radio protocols. The data can be stored
                using flash memory on the board, or saved to an SD card. A wide
                range of breakout pins allow the board to communicate to new
                sensors or transceivers, creating a versatile and expandable
                design.
              </p>
              <p>
                The node can be temporarily affixed to a structure for the
                duration of a test, or can be permanently installed (for example
                within a wind turbine blade) at manufacture.
              </p>
            </>
          ),
          image: (
            <picture>
              <source srcSet={nodeavif} type="image/avif" />
              <img
                src={nodejpg}
                className="card-img"
                alt="Our custom sensor node"
              />
            </picture>
          ),
        },
        {
          name: "Software",
          text: (
            <>
              <p>
                Our custom &apos;Eleven-I Lab&apos; software allows us to
                quickly capture and analyse data from multiple sensor nodes
                during testing.
              </p>
              <p>
                For long term testing our software runs on a central aggregator
                device, automatically collecting data from all the sensors and
                uploading it to our cloud platform.
              </p>
            </>
          ),
          image: (
            <img
              src={cloudlive}
              className="card-img"
              alt="Web page showing sensor data arriving on cloud platform"
            />
          ),
        },
        {
          name: "Cloud Platform",
          text: (
            <>
              <p>
                During long term monitoring, data is continuously uploaded to
                our cloud platform where you can view the status of all the
                sensors and the results of the online data analyis algorithms.
              </p>
              <p>
                Alerts can be configured (such as email or SMS) when problems
                are identified.
              </p>
            </>
          ),
          image: (
            <img
              src={cloud}
              className="card-img"
              alt="Dashboard showing alerts"
            />
          ),
        },
      ]}
    />
  </section>
);

export { Platform };
