import React from "react";

const TriangleRightFlowchart: React.FC<{ name: string }> = ({ name }) => (
  <svg
    aria-labelledby={name}
    id="svg"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    className={name}
    viewBox="0 0 1 2"
    preserveAspectRatio="none"
  >
    <polygon points="0,0, 1,1, 0,2" />
  </svg>
);

export { TriangleRightFlowchart };
