import React, { useEffect } from "react";

import { Ai } from "./svg/Ai";
import { BigData } from "./svg/BigData";
import { Sensor } from "./svg/Sensor";
import { Money } from "./svg/Money";

/*
 * I'm not sure why this works, but it does. I don't know why link has a hash
 * property since HTMLElements don't seem to have it
 */
interface SectionElement extends HTMLElement {
  hash: string;
}

const CoverPageNav: React.FC = () => {
  useEffect(() => {
    const mainNavLinks: NodeListOf<SectionElement> = document.querySelectorAll(
      ".on-page-nav ul li a"
    );
    const handleScroll = () => {
      const fromTop = window.scrollY;

      mainNavLinks.forEach((link: SectionElement) => {
        const section: HTMLElement | null = document.querySelector(link.hash);
        if (section !== null) {
          if (
            section.offsetTop <= fromTop &&
            section.offsetTop + section.offsetHeight > fromTop
          ) {
            link.classList.add("current");
          } else {
            link.classList.remove("current");
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <nav className="on-page-nav">
      <ul className="no-list-style">
        <li>
          <a href="#section-1">
            <Sensor />
          </a>
        </li>
        <li>
          <a href="#section-2">
            <Ai />
          </a>
        </li>
        <li>
          <a href="#section-3">
            <BigData />
          </a>
        </li>
        <li>
          <a href="#section-4">
            <Money />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export { CoverPageNav };
