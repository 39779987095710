import React from "react";

import nuclear from "../img/nuclear-min.jpg";
import transportMonJpeg from "../img/transport-monitoring/transport-monitoring.jpg";
import transportMonAvif from "../img/transport-monitoring/transport-monitoring.avif";
import transport from "../img/blade-transport.png";
import wagon from "../img/wagon-min.jpg";
import { MonilogLogo } from "../components/svg/MonilogLogo";

import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";

const Industrial: React.FC = () => (
  <section id="about">
    <PageHeader
      title="Industrial equipment transport monitoring"
      content={
        <>
          <h3>
            Instant alerts and analysis to identify &amp; prevent causes of
            breakage
          </h3>
          <p>
            Any project manager knows that transporting expensive, delicate and
            sensitive equipment is fraught with difficulties. And when a journey
            involves various changeovers at ports and depots, the scope for
            mishandling and accidental damage increases.
          </p>
        </>
      }
    />
    <PageContent
      imageWidth={6}
      content={[
        {
          name: "Transport Monitoring",
          image: (
            <>
              <MonilogLogo />
              <picture>
                <source srcSet={transportMonAvif} type="image/avif" />
                <img
                  src={transportMonJpeg}
                  alt="Transport Monitoring"
                  className="card-img"
                />
              </picture>
              <img
                src={transport}
                className="card-img"
                alt="Blade on trailer"
              />
            </>
          ),
          text: (
            <>
              <p>
                We offer an easy-to-use monitoring solution called MONILOG® RISK
                LOGGER. The system logs shocks, provides geo-tracking and can be
                used in a wide range of applications. The sensors are very
                durable, are approved for air freight. They can also record
                inclination, vibration, temperature, humidity, pressure, and
                light incidence. This is an out-of-the-box system that we can
                supply and provide analysis for if required.
              </p>
              <p>
                We also more bespoke sensors for more unique use-cases. Our
                transport monitoring system enables you to view the structural
                health of your equipment in real time, from preparation and
                shipping, through to the final unloading, inspection and
                installation stages. Our sensors are easy, quick and discrete to
                fit, so whether your equipment will be shipping months down the
                line, or very soon, we can help you. For longer term storage,
                transit and layup, we offer an offline data gathering solution
                which can be locally interrogated.
              </p>
              <p>
                With multiple sensor types able to pick up any minute vibrations
                and stresses that impact your equipment, alongside video
                recording and distance measurements, you will constantly have
                the full picture of what is happening during transit. And
                knowing this information is being collected and recorded will
                help promote a robust quality assurance framework throughout
                your logistical network.
              </p>
              <p>
                Should an issue be detected, real time alerts will inform you –
                and the driver – right away. This will enable preventative
                measures to be taken at the earliest possible point.
              </p>
              <p>
                And if breakage does occur, the Eleven-I solution will enable
                traceability of where, when and how your equipment received
                damage. Invaluable to have at hand when you need to resolve the
                issue with the relevant transport providers, your customers, and
                insurers.
              </p>
            </>
          ),
        },
        {
          name: "Load Validation",
          image: <img src={wagon} className="card-img" alt="Nuclear" />,
          text: (
            <>
              <p>
                Eleven-I’s sensors and real time analysis can also be applied to
                load validation trials, to help you understand the strains and
                environmental conditions your equipment will encounter during
                transportation.
              </p>
              <p style={{ fontWeight: "bold" }}>
                Preparing to transport high value and sensitive equipment?
              </p>
              <div className="center">
                <a href="/contact" className="btn btn-primary middle-button">
                  CONTACT US TODAY
                </a>
              </div>
            </>
          ),
        },
        {
          name: "Professional Services",
          text: (
            <p>
              Our pragmatic approach to investigating and resolving your plant
              issues brings laboratory grade sensing to your production and
              storage environments, alongside sophisticated analysis, and a
              results-driven approach to getting to the root of your issues. If
              there’s a problem, we’ve got a solution.
            </p>
          ),
        },
        {
          name: "Condition Monitoring",
          image: <img src={nuclear} className="card-img" alt="Nuclear" />,
          text: (
            <>
              <p>
                Our continuous analysis provides early identification of
                problems before they become more serious (and costly).
              </p>
              <p style={{ fontWeight: "bold" }}>
                Ready to talk about the benefits we can bring to your business?{" "}
              </p>
              <div className="center">
                <a href="/contact" className="btn btn-primary middle-button">
                  ARRANGE A CALL BACK
                </a>
              </div>
            </>
          ),
        },
      ]}
    />
  </section>
);

export { Industrial };
