import React from "react";

import bladeWobbling from "../img/blade-wobbling/blade-wobbling.jpg";
import psdJpg from "../img/psd/psd.jpg";
import psdAvif from "../img/psd/psd.avif";
import psdWebp from "../img/psd/psd.webp";
import balancing from "../img/balancing.jpg";
import phil from "../img/phil.jpg";

import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";

const Analysis: React.FC = () => (
  <section>
    <PageHeader title="Data Analysis" />
    <PageContent
      imageWidth={4}
      content={[
        {
          name: "Blade Structural Analysis",
          text: "Analysing the data from our accelerometer nodes can identify the resonant modes and damping characteristics of a blade. This can help to identify manufacturing variations or potential resonance issues. It can also be used to optimise blade matching.",
          image: (
            <img
              src={bladeWobbling}
              className="card-img"
              alt="Person working on blade"
            />
          ),
        },
        {
          name: "Damage Detection",
          text: "Using inter-node relationships, historical data and comparative data from other identical systems we can identify difficult-to-detect structural changes.",
          image: (
            <picture>
              <source srcSet={psdAvif} type="image/avif" />
              <source srcSet={psdWebp} type="image/webp" />
              <img
                src={psdJpg}
                className="card-img" alt="Graphs"
              />
            </picture>
          )
        },
        {
          name: "Rotor Balancing",
          text: "We can provide tools to facilitate on-tower rotor balancing.",
          image: <img src={balancing} className="card-img" alt="Weights" />,
        },
        {
          name: "Bespoke Analysis",
          text: "Our team of experienced mechanical and data-processing engineers can provide a bespoke data analysis service to make sense of your turbine data.",
          image: (
            <img
              src={phil}
              className="card-img"
              alt="Phil working on a computer"
            />
          ),
        },
      ]}
    />
  </section>
);

export { Analysis };
